import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .rowContainer {
    margin-top: -30px;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .tituloPagina {
    margin-top: 26px;
    margin-left: 11px;
    margin-bottom: 33px;
  }

  .containerSearch {
    display: flex !important;
    justify-content: flex-end;
  }

  .containerPagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;

    @media (max-width: 992px) {
      max-width: 100%;
      width: 100%;
    }
  }

  .containerInputText {
    width: 250px;
    height: 50px;
    marginright: 10px;
  }
  .buttonPesquisar {
    height: 40px;
    margin-top: 28px;
    background-color: #8850bf;
    border-color: #8850bf;
    margin-right: 10px;
    margin-left: 10px;
  }
  .paper {
    width: 100%;
    height: 100%;
    flex: 1;
    z-index: 1;
    display: flex;
    flex-direction: column;

    .containerMaterial {
      margin-top: -15px;
    }
  }

  .cellStyle {
    text-align: left;
    font-size: 12px;
    margin-top: 5px;
    display: flex;
  }
  .cellStyleSubTitle {
    text-align: left;
    font-size: 12px;
    margin-top: -20px;
  }

  .containerDeleteIcon {
    text-align: left;
    display: flex;
    margin-top: 10px;
    align-self: flex-start;
  }
  .priorIcon {
    margin-left: 10px;
    margin-top: -4px;
    display: flex;
  }

  .priorIcon p {
    margin-left: 10px;
    font-size: 20px;
    text-decoration: underline;
  }

  .buttonRowNotificacao {
    width: 100%;
    border: none;
    background-color: transparent;
    margin: 0 auto;
    font-weight: 400;
    // color: #3d3d3d;
    &:nth-child(2) {
      margin-left: 10px;
    }
  }
`;

export const ButtonRow = styled.button`
  flex: 1;
  border: none;
  background-color: transparent;
  justify-content: space-between;
  font-weight: 400;
  // color: #3d3d3d;
  &:nth-child(2) {
    margin-left: 10px;
  }
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
    color: #e5e5e5;
  }
`;

export const ButtonRowReturn = styled.button`
  display: flex;
  flex-directions: row;
  border: none;
  background-color: transparent;
  font-weight: 400;
  max-width: 130px;
  color: white;
  margin-top: 10px;
  &:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
    color: #e5e5e5;
  }
`;

export const ContainerLimit = styled.div`
  max-width: 220px;
  width: 100%;
  display: grid;
  flex-wrap: nowrap;
  gap: 10px;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin-right: 50px;
  margin-bottom: 10px;
  label {
    margin-top: 10px;
  }

  .select-container input {
    height: 40px !important;
    padding-left: 10px !important;
  }

  @media (max-width: 992px) {
    max-width: 100%;
    margin-top: 20px;
  }
`;

export const TableEmpty = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  h3 {
    font-size: 10pt;
    color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
`;

export const TableLoad = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  h3 {
    font-size: 10pt;
    color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
`;

export const PaginatedContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`;
