import * as yup from 'yup';
import { transformAsCurrency } from '~/utils/functions';

export const schema = yup
  .object({
    num_cest: yup
      .string()
      .required()
      .test((value) => {
        // Remove os caracteres especiais e verifica possui 7 digitos
        value = value?.replace(/\D/g, '');
        return value?.length === 7;
      }),
    per_iva: yup
      .string()
      .required()
      .test((value) => {
        const valueToFloat = transformAsCurrency(value || 0);
        return valueToFloat >= 0 && valueToFloat <= 100;
      }),
    des_cest: yup.string().required(),
  })
  .required();
