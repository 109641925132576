import Tooltip from '@atlaskit/tooltip';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { BiSortDown, BiSortUp } from 'react-icons/bi';
import { BsSortNumericDownAlt, BsSortNumericUpAlt } from 'react-icons/bs';
import { FaAngleLeft, FaAngleRight, FaEye, FaEyeSlash } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DefaultLoader from '~/components/DefaultLoader';
import FooterDefault from '~/components/FooterDefault';
import { InputRadio, InputSelect, InputText } from '~/components/NovosInputs';
import Pagination from '~/components/Pagination';
import icones from '~/config/icones';
import api from '~/services/api';
import { getNotification, removeNotification } from '~/services/user';

import {
  ButtonRow,
  ButtonRowReturn,
  Container,
  ContainerLimit,
  PaginatedContainer,
  TableEmpty,
  TableLoad,
} from './styles';
import { ContentRow, Prior } from './types';

import 'moment/locale/pt-br';
import { loadingContext } from '~/context/loading';

const MySwal = withReactContent(Swal);

const CentralDeNotificacao: React.FC = () => {
  moment.locale('pt-br');

  const {
    setLoadingNotificaoPage,
    setLoadingNotificaoTable,
    loadingNotificaoPage,
    loadingNotificaoTable,
  } = useContext(loadingContext);
  const [grid, setGrid] = useState<any[]>([]);
  const [rowsCount, setRowsCount] = useState(0);
  const [nivel, setNivel] = useState(0);
  const [init, setInit] = useState(false);

  // Filtros
  const [limit, setLimit] = useState(10);
  const [exibir, setExibir] = useState<number>(0);
  const [orderData, setOrderData] = useState(1);
  const [orderPrior, setOrderPrior] = useState(1);
  const [priorNotificacao, setPriorNotificacao] = useState<Prior>({
    label: 'Qualquer',
    value: -1,
  });

  const [page, setPage] = useState<number>(1);

  //  Notificaçoes from navbar
  const notiFromNavbar = getNotification();
  const [loadNotificacaoFromBar, setLoadNotificacaoFromBar] =
    useState<boolean>(false);
  const [notificationNavBar, setNotificationNavBar] = useState<boolean>(false);

  const [rowContent, setRowContent] = useState<ContentRow>({
    des_nome: '',
    cod_tela: undefined,
    des_notificacao: '',
    dta_cadastro_view: undefined,
    cod_notificacao: undefined,
    tipo_prioridade: undefined,
    label_menu: '',
  });

  const tiposPrioridades = [
    {
      label: 'Qualquer',
      value: -1,
    },
    {
      label: 'Crítica',
      value: 4,
    },
    {
      label: 'Alta',
      value: 3,
    },
    {
      label: 'Média',
      value: 2,
    },
    {
      label: 'Baixa',
      value: 1,
    },
    {
      label: 'Sem prioridade',
      value: 0,
    },
  ];

  const {
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const sleep = async (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const onVisualized = useCallback(
    async (row: any, isView?: boolean) => {
      setLoadingNotificaoTable(true);
      const gridVisualized = grid;
      setGrid([]);
      grid.map((item, index) => {
        if (item.cod_notificacao === row.cod_notificacao) {
          gridVisualized[index].flg_lido = isView || !row.flg_lido;
          if (exibir !== 0) {
            if (isView === true && exibir !== 2) {
              gridVisualized.splice(index, 1);
            }
            if (isView === false && exibir !== 1) {
              gridVisualized.splice(index, 1);
            }
          }
        }
        return gridVisualized;
      });
      setGrid(gridVisualized);

      await api.put(
        `/central-de-notificacoes/notificacoes/${row.cod_notificacao}`,
        {
          flg_lido: isView,
        },
      );
      setLoadingNotificaoTable(false);
    },
    [exibir, setLoadingNotificaoTable, grid],
  );

  const getNotificacaoFromNavbar = useCallback(async () => {
    setLoadNotificacaoFromBar(true);
    setNotificationNavBar(true);
    setLoadingNotificaoPage(true);
    const { data } = await api.get(
      `/central-de-notificacoes/${notiFromNavbar}`,
    );
    setRowContent(data.data[0]);
    onVisualized(data.data[0], true);
    setLoadNotificacaoFromBar(false);
    setLoadingNotificaoPage(false);
    await sleep(200);
    removeNotification();
  }, [notiFromNavbar, onVisualized, setLoadingNotificaoPage]);

  const getNotificacao = useCallback(
    async (limitValue?: number) => {
      setLoadingNotificaoTable(true);
      const values = getValues();
      const {
        inputDescricao,
        start,
        tipo_notificacao_filtro,
        orderBy,
        tipo_notificacao_prioridade,
      } = values;

      const { data } = await api.post(`/central-de-notificacoes/notificacoes`, {
        tipo_notificacao_filtro: Number(tipo_notificacao_filtro) || exibir || 0,
        des_pesquisa: inputDescricao,
        orderBy: orderBy || ['dta_cadastro desc', 'tipo_prioridade desc'],
        start: start || 0,
        tipo_notificacao_prioridade: tipo_notificacao_prioridade || -1,
        limit: limitValue || limit,
      });

      if (data.success) {
        setRowsCount(data.rowCount);
        setValue('codUltimaNotificacao', data.lastNotification);
        setGrid(data.data);
      } else {
        setGrid([]);
      }
      setInit(true);
      setLoadingNotificaoTable(false);
    },
    [limit, exibir, getValues, setValue, setLoadingNotificaoTable],
  );

  const handleKeyPress = (event: any) => {
    setValue('inputDescricao', event.target.value);
    getNotificacao();
  };

  const saveFilterUser = useCallback(async () => {
    const values = getValues();
    const { tipo_notificacao_filtro, orderBy } = values;

    let dataOrder;
    let priorOrder;

    if (orderBy) {
      dataOrder = orderBy.indexOf('dta_cadastro asc') === -1 ? 1 : 0;
      priorOrder = orderBy.indexOf('tipo_prioridade asc') === -1 ? 1 : 0;

      await api.post(`/central-de-notificacoes`, {
        tipo_notificacao_filtro: Number(tipo_notificacao_filtro) || 0,
        tipo_notificacao_ordenacao_dta: dataOrder,
        tipo_notificacao_ordenacao_prior: priorOrder,
        num_notificacao_reg_pag: limit,
      });
    }
  }, [getValues, limit]);

  useEffect(() => {
    (async () => {
      setLoadingNotificaoPage(true);
      const { data } = await api.get(`/central-de-notificacoes`);

      if (data.success) {
        setValue('tipo_notificacao_filtro', data.data.tipo_notificacao_filtro);
        setValue('tipo_notificacao_prioridade', -1);

        setExibir(data.data.tipo_notificacao_filtro);
        setLimit(data.data.num_notificacao_reg_pag);

        const pageLimit = {
          label: data.data.num_notificacao_reg_pag,
          value: data.data.num_notificacao_reg_pag,
        };

        setValue('pageLimit', pageLimit);

        const orderDataFilter =
          data.data.tipo_notificacao_ordenacao_dta === 0
            ? 'dta_cadastro asc'
            : 'dta_cadastro desc';
        const orderPriorFilter =
          data.data.tipo_notificacao_ordenacao_prior === 0
            ? 'tipo_prioridade asc'
            : 'tipo_prioridade desc';

        setOrderPrior(data.data.tipo_notificacao_ordenacao_prior);
        setOrderData(data.data.tipo_notificacao_ordenacao_dta);

        const array = [];
        array.push(orderDataFilter);
        array.push(orderPriorFilter);
        setValue('orderBy', array);

        setLoadingNotificaoPage(false);
      }
    })();
  }, [setValue]);

  //  quando clicado em uma notificação do navbar na tela de notificacao
  useEffect(() => {
    if (notiFromNavbar !== null) {
      setNivel(1);
      getNotificacaoFromNavbar();
      setExibir(0);
      getNotificacao();
    } else {
      setNivel(0);
    }
  }, [notiFromNavbar, getNotificacaoFromNavbar]);

  //  Toda vez que atualizar o filtro limit e exibir
  useEffect(() => {
    if (!loadingNotificaoTable && !loadingNotificaoPage) {
      setLoadingNotificaoTable(true);
      setPage(1);
      setValue('start', 0);
      saveFilterUser();
      getNotificacao();
    }
  }, [limit, exibir, loadingNotificaoPage]);

  function returnCount(): string {
    let newLimit = limit;
    if (limit > rowsCount) newLimit = rowsCount;
    if (page === 1) {
      return `1 - ${newLimit} de ${rowsCount}`;
    }
    const count = page * newLimit;
    if (count > rowsCount) {
      return `${count - limit + 1} - ${rowsCount} de ${rowsCount}`;
    }
    return `${count - limit + 1} - ${count} de ${rowsCount}`;
  }

  const handleLoadNewPage = useCallback(
    async (newPage: number) => {
      setValue(
        'start',
        Number(((newPage - 1) * limit).toString().padEnd(2, '0')),
      );
      setPage(newPage);
      getNotificacao();
    },
    [getNotificacao, setValue],
  );

  const Paginated: React.FC = () => {
    return (
      <PaginatedContainer>
        <ContainerLimit>
          <label htmlFor="limit">Registros por página</label>
          <InputSelect
            placeholder=""
            label=""
            className="limit"
            name="pageLimit"
            register={register}
            control={control}
            options={[
              { value: 10, label: '10' },
              { value: 20, label: '20' },
              { value: 30, label: '30' },
            ]}
            isError={false}
            changeSelected={async (option: {
              value: number;
              label: string;
            }) => {
              const { value } = option;
              setValue('pageLimit', option);
              setLimit(value);
            }}
          />
        </ContainerLimit>
        <div className="containerPagination">
          {rowsCount > 0 && rowsCount === 1 && (
            <p className="my-2">{rowsCount} registro encontrado.</p>
          )}
          {rowsCount > 1 && <p className="my-2">{returnCount()}</p>}
          {rowsCount <= 0 && <p className="my-2">0 registros encontrados.</p>}

          <Pagination
            onPageChange={handleLoadNewPage}
            currentPage={page}
            tela={205}
            updatePage={() => getNotificacao()}
            rowsCount={rowsCount}
            limit={limit}
          />
        </div>
      </PaginatedContainer>
    );
  };

  const onDelete = (row: any) => {
    MySwal.fire({
      title: 'Deseja excluir essa notificação?',
      text: ``,
      showCancelButton: true,
      confirmButtonColor: '#07289e',
      cancelButtonColor: '#ff7b7b',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { data } = await api.put(
            `/central-de-notificacoes/notificacoes/${row.cod_notificacao}/deletar`,
          );
          if (data.success) {
            setNivel(0);
            getNotificacao();
            toast.success(`Notificação removida com sucesso`);
          }
        } catch (e: any) {
          toast.warning(e.data.message);
        }
      }
    });
  };

  const onVisualizedAll = async (value: boolean) => {
    const values = getValues();
    const { codUltimaNotificacao } = values;

    MySwal.fire({
      title: `${
        value === true
          ? 'Deseja marcar todas as notificações como lidas?'
          : 'Deseja marcar todas as notificações como não lidas?'
      }`,
      text: ``,
      showCancelButton: true,
      confirmButtonColor: '#07289e',
      cancelButtonColor: '#ff7b7b',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { data } = await api.put(
            `/central-de-notificacoes/notificacoes/lidas/${codUltimaNotificacao}`,
            {
              flg_lido: value,
            },
          );
          if (data.success) {
            getNotificacao();
            toast.success('Registros atualizados com sucesso!');
          }
        } catch (e: any) {
          toast.warning(e.data.message);
        }
      }
    });
  };

  const onView = (row: any) => {
    setRowContent(row);
    if (exibir === 0 || exibir === 1) {
      onVisualized(row, true);
    }
    setNivel(1);
  };

  const orderByData = (value?: any) => {
    const values = getValues();
    const { orderBy } = values;
    const arrayOrderBy = orderBy;

    if (arrayOrderBy) {
      const orderPosition = arrayOrderBy.map((item: any, index: any) => {
        if (item === 'dta_cadastro asc' || item === 'dta_cadastro desc') {
          return index;
        }
        return false;
      });

      orderPosition.map((item: any, index: any) => {
        if (item !== false) {
          arrayOrderBy.splice(index, 1);
          arrayOrderBy.unshift(value);
        }
        return false;
      });
    }

    if (orderData === 1) {
      setValue('orderBy', arrayOrderBy);
      setOrderData(0);
    } else {
      setValue('orderBy', arrayOrderBy);
      setOrderData(1);
    }

    saveFilterUser();
    getNotificacao();
  };

  const orderByPrior = (value?: any) => {
    const values = getValues();
    const { orderBy } = values;
    const arrayOrderBy = orderBy;

    if (arrayOrderBy) {
      const orderPosition = arrayOrderBy.map((item: any, index: any) => {
        if (item === 'tipo_prioridade desc' || item === 'tipo_prioridade asc') {
          return index;
        }
        return false;
      });

      orderPosition.map((item: any, index: any) => {
        if (item !== false) {
          arrayOrderBy.splice(index, 1);
          arrayOrderBy.unshift(value);
        }
        return false;
      });
    }

    if (orderPrior === 1) {
      setValue('orderBy', arrayOrderBy);
      setOrderPrior(0);
    } else {
      setValue('orderBy', arrayOrderBy);
      setOrderPrior(1);
    }
    saveFilterUser();
    getNotificacao();
  };

  const getIconPrior = (prioridade: number | undefined) => {
    let content;
    let color;
    let iconDes: any;
    switch (prioridade) {
      case 0:
        content = 'Sem prioridade';
        color = 'gray';
        iconDes = 'BsChevronUp';
        break;
      case 1:
        content = 'Baixa';
        color = 'green';
        iconDes = 'BsChevronDoubleUp';
        break;
      case 2:
        content = 'Média';
        color = 'orange';
        iconDes = 'BsChevronUp';
        break;
      case 3:
        content = 'Alta';
        color = 'orange';
        iconDes = 'BsChevronDoubleUp';
        break;
      case 4:
        content = 'Crítica';
        color = '#e63c3c';
        iconDes = 'BsChevronDoubleUp';
        break;
      default:
    }

    const iconkey = Object.keys(icones).find((icon) => icon === 'Bs');
    let Icon = '';
    if (iconkey) {
      const lib = icones[iconkey];
      Icon = lib[iconDes];
    }
    return (
      <Tooltip position="bottom" content={content}>
        <div style={{ fontSize: '20px', color: `${color}` }}>
          <Icon />
        </div>
      </Tooltip>
    );
  };

  if (loadingNotificaoPage) {
    return (
      <Container>
        <div className="w-100 h-100 d-flex justify-conten-center align-items-center">
          <DefaultLoader />;
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <h3 className="tituloPagina">Central de Notificações</h3>
      <hr
        style={{ marginLeft: '10px', marginRight: '6px', marginBottom: '10px' }}
      />
      {nivel === 0 && (
        <Row className="rowContainer">
          <Col sm={12} md={8}>
            <Button
              className="buttonPesquisar"
              type="button"
              onClick={() => {
                onVisualizedAll(true);
              }}
              title="Marcar todas as notificações como lidas"
            >
              Marcar todas as notificações como lidas
            </Button>
            <Button
              className="buttonPesquisar"
              type="button"
              onClick={() => {
                onVisualizedAll(false);
              }}
              title="Marcar todas as notificações como não lidas"
            >
              Marcar todas as notificações como não lidas
            </Button>
          </Col>
          <Col sm={12} md={4}>
            <div className="containerSearch">
              <div className="containerInputText">
                <InputText
                  label=""
                  maxLength={50}
                  placeholder=""
                  name="inputDescricao"
                  register={register}
                  disabled={false}
                  isError={!!errors.inputDescricao}
                  onKeyPress={async (event: any) => {
                    if (event.key === 'Enter') {
                      handleKeyPress(event);
                    }
                  }}
                />
              </div>
              <Button
                className="buttonPesquisar"
                type="button"
                onClick={() => {
                  getNotificacao();
                }}
                title="Pesquisar"
              >
                Pesquisar
              </Button>
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Row style={{ marginLeft: '-8px', paddingRight: '14px' }}>
          <Col
            sm={12}
            style={{
              paddingLeft: '18px',
              paddingRight: '0px',
            }}
          >
            <Col
              sm={12}
              md={12}
              style={{ border: '1px solid rgba(224, 224, 224, 1)' }}
              className="dataTable"
            >
              <Paper className="paper">
                <TableContainer style={{ minHeight: '500px' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="col-sm-12 col-md-12">
                          {nivel === 1 && (
                            <Row>
                              <ButtonRowReturn
                                onClick={() => {
                                  setNivel(0);
                                  if (notificationNavBar) {
                                    setNotificationNavBar(false);
                                    const exibirValue = getValues(
                                      'tipo_notificacao_filtro',
                                    );

                                    if (exibirValue !== exibir)
                                      setExibir(exibirValue);

                                    getNotificacao();
                                  }
                                }}
                              >
                                <Col
                                  sm={12}
                                  md={1}
                                  style={{
                                    maxWidth: '20px',
                                    marginRight: '10px',
                                  }}
                                >
                                  <FaAngleLeft
                                    size={30}
                                    onClick={() => {
                                      setNivel(0);
                                    }}
                                  />
                                </Col>
                                <Col
                                  sm={12}
                                  md={1}
                                  style={{
                                    marginTop: '5px',
                                    marginLeft: '20px',
                                  }}
                                >
                                  <div>
                                    <p>NOTIFICAÇÕES</p>
                                  </div>
                                </Col>
                              </ButtonRowReturn>
                              <Col
                                sm={12}
                                md={1}
                                style={{
                                  marginTop: '15px',
                                  marginLeft: '10px',
                                }}
                              >
                                <div>{`/ #${rowContent.cod_notificacao}`}</div>
                              </Col>
                            </Row>
                          )}

                          {nivel === 0 && (
                            <Row
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Col
                                sm={12}
                                md={4}
                                style={{
                                  marginTop: '5px',
                                  maxWidth: '320px',
                                }}
                              >
                                NOTIFICAÇÕES
                              </Col>
                              <Col
                                sm={12}
                                md={3}
                                style={{ display: 'flex', marginTop: '5px' }}
                              >
                                <div>Prioridade: </div>
                                <div
                                  style={{
                                    marginTop: '-15px',
                                    marginLeft: '5px',
                                  }}
                                >
                                  <InputSelect
                                    options={tiposPrioridades}
                                    label=""
                                    placeholder="- Selecione o tipo -"
                                    name="prior"
                                    register={register}
                                    value={priorNotificacao.label}
                                    isError={!!errors.prior}
                                    control={control}
                                    changeSelected={(selected: any) => {
                                      setPriorNotificacao(selected);
                                      setValue(
                                        'tipo_notificacao_prioridade',
                                        selected.value,
                                      );
                                      saveFilterUser();
                                      getNotificacao();
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col
                                sm={12}
                                md={4}
                                style={{
                                  display: 'flex',
                                  marginTop: '5px',
                                }}
                              >
                                Exibir:
                                <div
                                  style={{
                                    display: 'flex',
                                    marginTop: '-7px',
                                  }}
                                >
                                  <InputRadio
                                    label="Todos"
                                    name="tipo_notificacao_filtro"
                                    colorLabel="color-white"
                                    value={0}
                                    register={register}
                                    disabled={loadingNotificaoTable}
                                    checked={exibir === 0}
                                    onChange={() => {
                                      setValue('tipo_notificacao_filtro', 0);
                                      setExibir(0);
                                    }}
                                    isError={!!errors.tipoGrupoEmpresa}
                                  />
                                  <InputRadio
                                    label="Não lidas"
                                    name="tipo_notificacao_filtro"
                                    value={1}
                                    colorLabel="color-white"
                                    register={register}
                                    disabled={loadingNotificaoTable}
                                    checked={exibir === 1}
                                    onChange={() => {
                                      setValue('tipo_notificacao_filtro', 1);
                                      setExibir(1);
                                    }}
                                    isError={!!errors.tipoGrupoEmpresa}
                                  />
                                  <InputRadio
                                    label="Lidas"
                                    name="tipo_notificacao_filtro"
                                    colorLabel="color-white"
                                    value={2}
                                    register={register}
                                    disabled={loadingNotificaoTable}
                                    checked={exibir === 2}
                                    onChange={() => {
                                      setValue('tipo_notificacao_filtro', 2);

                                      setExibir(2);
                                    }}
                                    isError={!!errors.tipoGrupoEmpresa}
                                  />
                                </div>
                              </Col>
                              <Col
                                sm={12}
                                md={1}
                                style={{ display: 'flex', marginTop: '5px' }}
                              >
                                {orderPrior === 0 && (
                                  // asc
                                  <BiSortUp
                                    title="Notificações menos críticas listadas primeiramente"
                                    onClick={() =>
                                      orderByPrior('tipo_prioridade desc')
                                    }
                                    size={30}
                                  />
                                )}
                                {orderPrior === 1 && (
                                  // desc
                                  <BiSortDown
                                    title="Notificações mais críticas listadas primeiramente "
                                    onClick={() =>
                                      orderByPrior('tipo_prioridade asc')
                                    }
                                    size={30}
                                  />
                                )}

                                {orderData === 0 && (
                                  // asc
                                  <BsSortNumericUpAlt
                                    title="Notificações mais antigas listadas primeiramente"
                                    onClick={() =>
                                      orderByData('dta_cadastro desc')
                                    }
                                    size={30}
                                  />
                                )}
                                {orderData === 1 && (
                                  // desc
                                  <BsSortNumericDownAlt
                                    title="Notificações mais novas listadas primeiramente"
                                    onClick={() =>
                                      orderByData('dta_cadastro asc')
                                    }
                                    size={30}
                                  />
                                )}
                              </Col>
                            </Row>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ minWidth: '200px' }}>
                      {loadingNotificaoTable && nivel === 0 && (
                        <TableRow>
                          <TableCell>
                            <Row>
                              <Col
                                sm={12}
                                md={12}
                                style={{
                                  position: 'relative',
                                  bottom: '-10px',
                                  minHeight: '500px',
                                }}
                              >
                                {' '}
                                <TableLoad>
                                  <DefaultLoader />
                                </TableLoad>
                              </Col>
                            </Row>
                          </TableCell>
                        </TableRow>
                      )}
                      {nivel === 1 && loadNotificacaoFromBar === false && (
                        <TableRow>
                          <TableCell>
                            <Row>
                              <Col sm={12} md={11} className="cellStyle">
                                <h5>{rowContent.des_nome} </h5>
                                <div className="priorIcon">
                                  {getIconPrior(rowContent.tipo_prioridade)}

                                  {rowContent.tipo_prioridade === 4 && (
                                    <p>PRIORIDADE: CRÍTICA</p>
                                  )}
                                  {rowContent.tipo_prioridade === 3 && (
                                    <p>PRIORIDADE: ALTA</p>
                                  )}
                                  {rowContent.tipo_prioridade === 2 && (
                                    <p>PRIORIDADE: MÉDIA</p>
                                  )}
                                  {rowContent.tipo_prioridade === 1 && (
                                    <p>PRIORIDADE: BAIXA</p>
                                  )}
                                  {rowContent.tipo_prioridade === 0 && (
                                    <p>PRIORIDADE: SEM PRIORIDADE</p>
                                  )}
                                </div>
                              </Col>

                              <Col
                                sm={12}
                                md={1}
                                className="containerDeleteIcon"
                              >
                                <ButtonRow
                                  type="button"
                                  onClick={() => {
                                    onDelete(rowContent);
                                  }}
                                  title="Excluir"
                                >
                                  <MdDeleteForever
                                    size={45}
                                    style={{ color: '#e63c3c' }}
                                  />
                                </ButtonRow>
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                sm={12}
                                md={11}
                                className="cellStyleSubTitle"
                              >
                                <p style={{ fontSize: '15px' }}>
                                  Tela da rotina: {rowContent.label_menu}
                                </p>
                              </Col>
                            </Row>
                            <hr />
                            <Row>
                              <Col
                                sm={12}
                                md={11}
                                className="cellStyle"
                                style={{
                                  height: '300px',
                                }}
                              >
                                <div
                                  // eslint-disable-next-line react/no-danger
                                  dangerouslySetInnerHTML={{
                                    __html: rowContent.des_notificacao,
                                  }}
                                  style={{ fontSize: '13px' }}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12} md={11} className="cellStyle">
                                {`${rowContent.dta_cadastro_view} -
                                ${moment(
                                  rowContent.dta_cadastro_view,
                                  'DD/MM/YYYY HH:mm:ss',
                                ).fromNow()}`}
                              </Col>
                            </Row>
                          </TableCell>
                        </TableRow>
                      )}

                      {grid.length > 0 &&
                        loadingNotificaoTable === false &&
                        grid.map((row) => (
                          <TableRow key={`${row.name}${nanoid()}`}>
                            {nivel === 0 && (
                              <>
                                <TableCell
                                  style={{
                                    backgroundColor: `${
                                      row.flg_lido ? '' : '#FFFFA4'
                                    }`,
                                    paddingLeft: '0px',
                                    paddingRight: '0px',
                                  }}
                                >
                                  <strong>
                                    <Row className="buttonRowNotificacao">
                                      <Col
                                        sm={12}
                                        md={1}
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        {getIconPrior(row.tipo_prioridade)}
                                      </Col>
                                      <Col
                                        sm={12}
                                        md={9}
                                        onClick={() => {
                                          if (nivel === 0) onView(row);
                                        }}
                                      >
                                        <Row
                                          style={{
                                            textAlign: 'left',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '700px',
                                            display: 'block',
                                          }}
                                        >
                                          {`#${row.cod_notificacao} - ${row.des_nome}`}
                                        </Row>
                                        <Row className="cellStyle">
                                          {`${row.dta_cadastro_view} -
                                          ${moment(
                                            row.dta_cadastro_view,
                                            'DD/MM/YYYY HH:mm:ss',
                                          ).fromNow()}`}
                                        </Row>
                                      </Col>
                                      <Col
                                        sm={12}
                                        md={2}
                                        style={{
                                          textAlign: 'left',
                                          display: 'flex',
                                          marginTop: '-10px',
                                          height: '50px',
                                        }}
                                      >
                                        <ButtonRow
                                          type="button"
                                          onClick={() => onDelete(row)}
                                          title="Excluir"
                                        >
                                          <MdDeleteForever
                                            size={30}
                                            style={{ color: '#e63c3c' }}
                                          />
                                        </ButtonRow>
                                        <ButtonRow
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseOne"
                                          aria-expanded="false"
                                          aria-controls="collapseOne"
                                          title={`Marcar como ${
                                            row.flg_lido ? 'não lido' : 'lido'
                                          }`}
                                          onClick={(e: any) => {
                                            e.preventDefault();
                                          }}
                                        >
                                          {row.flg_lido && (
                                            <>
                                              <FaEye
                                                size={30}
                                                style={{ color: '#72ab90' }}
                                                onClick={() =>
                                                  onVisualized(row, false)
                                                }
                                              />
                                            </>
                                          )}

                                          {!row.flg_lido && (
                                            <>
                                              <FaEyeSlash
                                                size={30}
                                                style={{ color: '#72ab90' }}
                                                onClick={() =>
                                                  onVisualized(row, true)
                                                }
                                              />
                                            </>
                                          )}
                                        </ButtonRow>
                                        <ButtonRow
                                          type="button"
                                          title="Visualizar"
                                          onClick={() => onView(row)}
                                        >
                                          <FaAngleRight
                                            size={30}
                                            style={{ color: '#3c7ae6' }}
                                          />
                                        </ButtonRow>
                                      </Col>
                                    </Row>
                                  </strong>
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        ))}
                      {grid.length === 0 && !loadingNotificaoTable && init && (
                        <TableRow>
                          <TableCell>
                            <Row>
                              <Col
                                sm={12}
                                md={12}
                                style={{
                                  position: 'relative',
                                  bottom: '-10px',
                                }}
                              >
                                {' '}
                                <TableEmpty>
                                  <h3>
                                    Nenhum título encontrado conforme critérios
                                    informados!
                                  </h3>
                                </TableEmpty>
                              </Col>
                            </Row>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <FooterDefault codTela={205}>
                  {nivel === 0 && <Paginated />}
                </FooterDefault>
              </Paper>
            </Col>
          </Col>
        </Row>
      </Row>
    </Container>
  );
};

export default CentralDeNotificacao;
